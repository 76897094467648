import React from 'react'
import PageHeader from '../../components/PageHeader'
import { Link } from 'react-router-dom'
import HeaderActionButton from '../../components/HeaderActionButton'
import Spin from '../../../common/ui/Spin'
import { GET_WORKFLOWS_QUERY } from '../../graphql/queries/workflow'
import { useQuery } from '@apollo/client'
import { WorkflowsQuery, WorkflowsQueryVariables } from 'app/javascript/components/graphql'
import EmptyState from '../../../Walkthrough/ui/EmptyState'
import AlertCard from '../../components/AlertCard'

const Alerts = () => {
  const { data, loading } = useQuery<WorkflowsQuery, WorkflowsQueryVariables>(GET_WORKFLOWS_QUERY, {
    fetchPolicy: 'network-only', // always fetches from the network, skips cache
  })

  const workflows = data?.workflows

  return (
    <React.Fragment>
      <PageHeader title="Alerts" breadCrumbs={[{ title: 'Alerts' }]}>
        <Link to="/alert/create">
          <HeaderActionButton label="Create" />
        </Link>
      </PageHeader>
      {loading ? (
        <Spin />
      ) : (
        <>
          {workflows?.length === 0 ? (
            <EmptyState title="No alerts yet" description="Create your first alert" />
          ) : (
            <div className="flex flex-col space-y-4">
              {workflows?.map((workflow) => (
                <AlertCard key={workflow.uuid} workflow={workflow} size={'large'} />
              ))}
            </div>
          )}
        </>
      )}
    </React.Fragment>
  )
}
export default Alerts
