import React from 'react'
import { AgentChat } from '../app/OneAgentChat'
import { useQuery } from '@apollo/client'
import { LinearProgress } from '@mui/material'
import { GET_AGENT_QUERY } from '../../graphql/queries/agent'
import { AgentQuery, AgentQueryVariables } from 'app/javascript/components/graphql'

interface ChatThreadProps {
  agentUuid: string | null
  threadUuid: string | null
  query: string | null
}

const ChatThread = ({ agentUuid, threadUuid, query }: ChatThreadProps) => {
  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    skip: !agentUuid,
    variables: {
      uuid: agentUuid,
    },
  })
  const agent = data?.agent

  if (!agentUuid || (!threadUuid && !query)) {
    return <></>
  }

  return (
    <div className="h-full overflow-y-auto">
      {loading || !agent ? (
        <div>
          <LinearProgress />
        </div>
      ) : (
        <AgentChat agent={agent} threadUuidFromUrl={threadUuid} queryFromUrl={query} />
      )}
    </div>
  )
}

export default ChatThread
