import React from 'react'
import {
  DELETE_TASK_MUTATION,
  GET_TASK_QUERY,
  UPDATE_TASK_MUTATION,
} from '../../graphql/queries/task'
import {
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
  TaskQuery,
  TaskQueryVariables,
  DeleteTaskMutation,
  DeleteTaskMutationVariables,
} from '../../../graphql'
import Spin from '../../../common/ui/Spin'
import { useMutation, useQuery } from '@apollo/client'
import PageHeader from '../../components/PageHeader'
import DynamicForm from '../../components/DynamicForm'
import { useHistory } from 'react-router-dom'
import { taskFormData } from '../../components/CreateTask'
import DeleteModal from '../../components/DeleteModal'
import HeaderActionButton from '../../components/HeaderActionButton'

const EditTask = ({ uuid }: { uuid: string }) => {
  const [isDeleting, setIsDeleting] = React.useState(false)

  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const [isSaving, setIsSaving] = React.useState(false)
  const history = useHistory()
  const { data, loading } = useQuery<TaskQuery, TaskQueryVariables>(GET_TASK_QUERY, {
    variables: { uuid },
  })

  const [updateTask] = useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UPDATE_TASK_MUTATION
  )

  const [deleteTask] = useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DELETE_TASK_MUTATION
  )

  if (loading) {
    return <Spin />
  }

  const task = data?.task
  const taskableLinkFragment = task.taskableType == 'Workflow' ? 'workflow' : 'analysis'

  const handleSubmit = async (values: any) => {
    setIsSaving(true)
    const response = await updateTask({
      variables: {
        uuid,
        name: values.name,
        description: values.description,
        task: values.task,
        taskType: values.taskType,
        outputWebhookUrl: values.outputWebhookUrl,
        runSchedule: values.runSchedule,
      },
    })
    if (response.data?.updateTask.errors.length > 0) {
      window.toastr.error('Error updating task')
      history.push('/' + taskableLinkFragment + '/' + task.taskable.uuid)
    } else {
      window.toastr.success('Task updated successfully')
      history.push('/task/' + task.uuid)
    }
    setIsSaving(false)
    return
  }

  const handleCancel = () => {
    history.push('/task/' + task.uuid)
  }

  const initialData = {
    name: task?.name,
    description: task?.description,
    task: task?.task,
    outputWebhookUrl: task?.outputWebhookUrl,
    runSchedule: task?.runSchedule,
    taskType: task?.taskType,
  }

  const handleDelete = async () => {
    const resposne = await deleteTask({
      variables: { uuid: task.uuid },
    })
    if (resposne.data.deleteTask.errors.length > 0) {
      window.toastr.error('Error deleting task')
    } else {
      window.toastr.success('Task deleted successfully')
    }
    history.push('/' + taskableLinkFragment + '/' + task.taskable.uuid)
    setIsDeleting(false)
  }

  const pages = []
  if (task.taskableType == 'Workflow') {
    pages.push({ title: 'Alerts', to: '/alerts' })
    pages.push({ title: task.taskable.name, to: `/alert/${task.taskable.uuid}` })
  } else if (task.taskableType == 'Analysis') {
    pages.push({ title: 'Analyses', to: '/analyses' })
    pages.push({ title: task.taskable.name, to: `/analysis/${task.taskable.uuid}` })
  }

  return (
    <>
      <PageHeader
        title={task.name}
        breadCrumbs={[
          ...pages,
          { title: task.name, to: `/task/${task.uuid}` },
          { title: 'Edit Task' },
        ]}
      >
        <HeaderActionButton label="Delete" onClick={() => setIsDeleting(true)} />
      </PageHeader>
      <DeleteModal
        open={isDeleting}
        setOpen={setIsDeleting}
        buttonLabel={'Delete'}
        title={'Delete Task'}
        onDelete={handleDelete}
        description={'Are you sure you want to delete the task? This cannot be undone.'}
      />
      <div className="max-w-2xl">
        <DynamicForm
          ref={formRef}
          formData={taskFormData({ taskableType: task.taskableType })}
          initialData={initialData}
          handleSubmit={handleSubmit}
        />

        <div className="flex flex-row space-x-2">
          <button
            type="submit"
            className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
            onClick={() => formRef.current?.submitForm()}
          >
            {isSaving ? <Spin /> : 'Save'}
          </button>

          <button
            onClick={handleCancel}
            className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-slate-600 bg-gray-200 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-gray-300"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}
export default EditTask
