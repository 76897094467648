import React, { useEffect, useState } from 'react'
import { GET_MESSAGE_THREADS } from '../../graphql/queries/message_thread'
import {
  GetMessageThreadsQuery,
  GetMessageThreadsQueryVariables,
} from 'app/javascript/components/graphql'
import { useQuery } from '@apollo/client'
import LinearProgress from '@mui/material/LinearProgress'
import {
  getDateCategory,
  getRelativeTimeString,
} from '../../../../components/common/utils/dateDisplay'
import { BotIcon, PlusCircle } from 'lucide-react'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'

const EmptyThreadList = () => {
  return (
    <div className="text-sm text-gray-500 text-center pt-48">
      Select an agent to start a conversation
    </div>
  )
}

interface ThreadListProps {
  agentUuid: string | null
  onThreadClick: (threadUuid: string) => void
  onNewThreadClick: () => void
}

const ThreadList = ({ agentUuid, onThreadClick, onNewThreadClick }: ThreadListProps) => {
  const history = useHistory()
  const [selectedThreadUuid, setSelectedThreadUuid] = useState<string | null>(null)
  const { data, loading, refetch } = useQuery<
    GetMessageThreadsQuery,
    GetMessageThreadsQueryVariables
  >(GET_MESSAGE_THREADS, {
    skip: !agentUuid,
    variables: {
      agentUuid,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    // refetch if thread_uuid changes in the url
    if (agentUuid && history.location.search.includes('thread_uuid')) {
      refetch({
        agentUuid,
      })
    }
  }, [agentUuid, history.location.search])

  const handleThreadClick = (threadUuid: string) => {
    setSelectedThreadUuid(threadUuid)
    onThreadClick(threadUuid)
  }

  const messageThreads = data?.getMessageThreads || []
  let lastCategory: string = null
  return (
    <div className="flex flex-col w-48 h-full border-r border-gray-200 bg-gray-100 overflow-y-auto">
      {!agentUuid && <EmptyThreadList />}

      {loading && (
        <div className="flex-grow">
          <LinearProgress />
        </div>
      )}

      {agentUuid && (
        <div className="text-sm font-semibold px-4 py-2 flex flex-row items-center justify-between">
          <div>Conversations</div>
          <Link
            to={`/agent/${agentUuid}`}
            className="flex flex-row items-center justify-center hover:bg-gray-200 hover:text-gray-800 rounded-full cursor-pointer text-gray-600"
          >
            <BotIcon className="h-4 w-4" />
          </Link>
          <div
            className="flex flex-row items-center justify-center hover:bg-gray-200 hover:text-gray-800 rounded-full cursor-pointer text-gray-600"
            onClick={onNewThreadClick}
          >
            <PlusCircle className="h-4 w-4" />
          </div>
        </div>
      )}

      <div className="flex flex-col text-xs px-4">
        {messageThreads.map((thread) => {
          const currentCategory = getDateCategory(thread.createdAt)
          const showDivider = lastCategory !== currentCategory
          lastCategory = currentCategory

          return (
            <React.Fragment key={thread.uuid}>
              {showDivider && <div className="divider my-2">{currentCategory}</div>}
              <div
                className={clsx(
                  'flex flex-row items-center justify-between p-2 rounded-xl cursor-pointer hover:bg-gray-200',
                  thread.uuid === selectedThreadUuid && 'bg-gray-200'
                )}
                onClick={() => handleThreadClick(thread.uuid)}
              >
                <div className="text-gray-600 flex flex-row space-x-2 justify-start">
                  <span className="text-xs line-clamp-1">
                    Chat ({getRelativeTimeString(thread.createdAt)})
                  </span>
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </div>

      {!loading && agentUuid && messageThreads.length === 0 && (
        <div className="text-sm text-gray-500 text-center pt-48">Start a new conversation</div>
      )}
    </div>
  )
}

export default ThreadList
