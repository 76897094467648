import React from 'react'
import EmptyContent from './EmptyContent'
import { GET_AGENT_WORKFLOWS, GET_WORKFLOW_TEMPLATES } from '../../../graphql/queries/workflow'
import { useQuery } from '@apollo/client'
import Spin from '../../../../common/ui/Spin'
import {
  AgentWorkflowsQuery,
  AgentWorkflowsQueryVariables,
  WorkflowTemplatesQuery,
  WorkflowTemplatesQueryVariables,
} from 'app/javascript/components/graphql'
import AlertCard from '../../../components/AlertCard'
import AlertTemplateCard from '../AlertTempalteCard'

interface AlertListProps {
  agentUuid: string
  onUpdate: () => void
}

const AlertList = ({ agentUuid, onUpdate }: AlertListProps) => {
  const { data, loading, refetch } = useQuery<AgentWorkflowsQuery, AgentWorkflowsQueryVariables>(
    GET_AGENT_WORKFLOWS,
    {
      variables: {
        agentUuid,
      },
      fetchPolicy: 'network-only',
    }
  )

  const { data: workflowTemplatesData } = useQuery<
    WorkflowTemplatesQuery,
    WorkflowTemplatesQueryVariables
  >(GET_WORKFLOW_TEMPLATES, {
    variables: {
      agentUuid,
    },
  })

  const workflowTemplates = workflowTemplatesData?.workflowTemplates || []

  if (loading) {
    return (
      <div>
        <Spin />
      </div>
    )
  }

  const workflows = data?.agentWorkflows

  const handleUpdate = () => {
    refetch()
    onUpdate()
  }

  return (
    <div className="flex flex-col space-y-5">
      <h3 className="text-xl font-semibold mb-5 pb-5 border-b">Alerts</h3>
      <div className="flex flex-col ml-12 space-y-4">
        <h4 className="text-lg font-semibold">Active</h4>
        <div className="flex flex-wrap">
          {workflows.length == 0 && (
            <EmptyContent title="No alerts are associated with this agent." />
          )}
          {workflows?.map((workflow) => (
            <div className="m-2" key={workflow.uuid}>
              <AlertCard workflow={workflow} size={'small'} />
            </div>
          ))}
        </div>
      </div>

      {workflowTemplates.length > 0 && (
        <div className="flex flex-col ml-12 space-y-4">
          <h4 className="text-lg font-semibold">Templates</h4>
          <div className="flex flex-wrap">
            {workflowTemplates?.map((workflowTemplate) => (
              <div className="m-2" key={workflowTemplate.uuid}>
                <AlertTemplateCard
                  agentUuid={agentUuid}
                  workflowTemplate={workflowTemplate}
                  onUpdate={handleUpdate}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
export default AlertList
