import React from 'react'
import { Link } from 'react-router-dom'
import { WorkflowQuery } from '../../graphql'
import { clsx } from 'clsx'
import { ShieldCheckIcon, ShieldExclamationIcon } from '@heroicons/react/24/solid'
import { ArrowRightCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline'

const workflowStatusColor = (workflow: WorkflowQuery['workflow']) => {
  const failingTasks = workflow.tasks.filter(
    (task: WorkflowQuery['workflow']['tasks'][0]) => task.status == 'fail'
  ).length

  const pendingTasks = workflow.tasks.filter(
    (task: WorkflowQuery['workflow']['tasks'][0]) => task.status == 'pending'
  ).length

  let statusColor = 'red'
  if (failingTasks == 0 && pendingTasks == 0) {
    statusColor = 'green'
  } else if (failingTasks == 0 && pendingTasks > 0) {
    statusColor = 'yellow'
  } else if (failingTasks > 0) {
    statusColor = 'red'
  }

  return 'bg-' + statusColor + '-200'
}

const workflowCompletionRate = (workflow: WorkflowQuery['workflow']) => {
  const totalTasks = workflow.tasks.length
  const passingTasks = workflow.tasks.filter(
    (task: WorkflowQuery['workflow']['tasks'][0]) => task.status == 'pass'
  ).length
  return passingTasks + ' / ' + totalTasks
}

const isWorkflowComplete = (workflow: WorkflowQuery['workflow']) => {
  const totalTasks = workflow.tasks.length
  const passingTasks = workflow.tasks.filter(
    (task: WorkflowQuery['workflow']['tasks'][0]) => task.status == 'pass'
  ).length
  return totalTasks == passingTasks
}

const isWorkflowFailing = (workflow: WorkflowQuery['workflow']) => {
  const failingTasks = workflow.tasks.filter(
    (task: WorkflowQuery['workflow']['tasks'][0]) => task.status == 'fail'
  ).length
  return failingTasks > 0
}

interface AlertCardProps {
  workflow: WorkflowQuery['workflow']
  size?: 'small' | 'medium' | 'large'
}

interface SizedAlertCardProps {
  workflow: WorkflowQuery['workflow']
}

const SmallAlertCard = ({ workflow }: SizedAlertCardProps) => {
  return (
    <>
      <Link
        to={`/alert/${workflow.uuid}`}
        className="w-64 h-34 flex flex-col border rounded-xl px-4 py-4 hover:shadow-xl hover:cursor-pointer"
      >
        <div className="flex flex-row">
          <div className={clsx(workflowStatusColor(workflow))}>
            <div className="text-xs text-gray-600">Status</div>
            <div className="text-md">{workflowCompletionRate(workflow)}</div>
          </div>
          <div className="ml-2">
            <h2 className="text-md text-gray-800 text-ellipsis">{workflow.name}</h2>
            <p className="text-xs line-clamp-2 text-gray-600">{workflow.description}</p>
          </div>
        </div>
      </Link>
    </>
  )
}

const MediumAlertCard = ({ workflow }: SizedAlertCardProps) => {
  return (
    <div key={workflow.id} className="flex flex-col">
      <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200 sm:px-6">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <div className="stats shadow">
              <div className={clsx('stat', workflowStatusColor(workflow))}>
                <div className="stat-title">Status</div>
                <div className="stat-value">{workflowCompletionRate(workflow)}</div>
                <div className="stat-desc">tasks are passing</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-4">
            <div className="text-sm font-medium text-gray-900">{workflow.name}</div>
            <div className="text-sm text-gray-500">{workflow.description}</div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center justify-center px-2 py-0.5 text-xs text-green-800 bg-green-100 rounded-full">
            Active
          </div>
        </div>
      </div>
      <div className="px-4 py-4 bg-gray-50 sm:px-6">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <div className="text-sm font-medium text-gray-900">Prompts</div>
            <div className="text-sm text-gray-500">Manage the prompts for this workflow</div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-center px-2 py-0.5 text-xs text-green-800 bg-green-100 rounded-full">
              Active
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const LargeAlertCard = ({ workflow }: SizedAlertCardProps) => {
  return (
    <>
      <div key={workflow.uuid} className="relative flex flex-row border rounded-xl bg-white p-4">
        <div className="flex flex-col space-y-4 items-center justify-top border-r pr-2">
          <AlertStatus workflow={workflow} />
        </div>
        <div className="flex flex-col flex-auto ml-6">
          <div className="whitespace-nowrap text-xl pt-3 font-medium text-gray-900">
            <Link to={`/alert/${workflow.uuid}`} className="hover:underline">
              {workflow.name}
            </Link>
          </div>
          <div className="flex flex-row mb-3 space-x-4 ml-1 text-xs text-gray-500">
            by &nbsp;
            <Link to={`/agent/${workflow.agent.uuid}`} className="hover:underline">
              {workflow.agent.label}
            </Link>
          </div>
          <div className="text-sm text-gray-500">{workflow.description}</div>
          <div className="flex justify-end">
            <Link
              to={`/alert/${workflow.uuid}`}
              className="hover:underline text-flowmo-blue-900 hover:text-flowmo-blue-600 group flex flex-row justify-center text-sm items-center"
            >
              <div>view</div>
              <ArrowRightCircleIcon
                className="size-6 group-hover:text-flowmo-blue-600"
                aria-hidden="true"
              />
            </Link>
          </div>
        </div>

        <div className="absolute top-5 right-5 flex flex-row space-x-2">
          <Link
            to={`/alert/edit/${workflow.uuid}`}
            className="text-flowmo-blue-900 hover:text-flowmo-blue-600"
          >
            <PencilSquareIcon className="size-6" aria-hidden="true" />
          </Link>
        </div>
      </div>
    </>
  )
}

export const AlertShield = ({
  workflow,
  size,
}: {
  workflow: WorkflowQuery['workflow']
  size?: string
}) => {
  if (!size) {
    size = '12'
  }
  return (
    <>
      {isWorkflowComplete(workflow) ? (
        <ShieldCheckIcon className={clsx('size-' + size, 'text-green-500')} aria-hidden="true" />
      ) : (
        <>
          {isWorkflowFailing(workflow) ? (
            <ShieldExclamationIcon
              className={clsx('size-' + size, 'text-red-500')}
              aria-hidden="true"
            />
          ) : (
            <ShieldExclamationIcon
              className={clsx('size-' + size, 'text-yellow-500')}
              aria-hidden="true"
            />
          )}
        </>
      )}
    </>
  )
}

export const AlertStatus = ({ workflow }: { workflow: WorkflowQuery['workflow'] }) => {
  return (
    <div className="flex flex-col space-y-2">
      <AlertShield size={'12'} workflow={workflow} />
      <div className={clsx('flex flex-col items-center', workflowStatusColor(workflow))}>
        <div className="text-xs font-normal text-gray-600">Status</div>
        <div className="text-base">{workflowCompletionRate(workflow)}</div>
      </div>
    </div>
  )
}

const AlertCard = ({ workflow, size }: AlertCardProps) => {
  if (size == 'small') {
    return <SmallAlertCard workflow={workflow} />
  } else if (size == 'medium') {
    return <MediumAlertCard workflow={workflow} />
  } else {
    return <LargeAlertCard workflow={workflow} />
  }
}

export default AlertCard
