import { gql } from '@apollo/client'
import { COMPANY_AGENT_FIELDS, DATA_SET_FIELDS, PLUGIN_FIELDS, USER_FIELDS } from './fragments'

export const GET_ORGANIZATION_AGENTS_QUERY = gql`
  query organizationAgents {
    organizationAgents {
      firstName
      label
      jobTitle
      profileImageUrl
      shortDescription
      description
      primer
      backend
      uuid
      type
      prompts
      id
      isPublic
      isExpert
      isCustom
      isCompany
      createdAt
      createdBy {
        ...UserFields
      }

      plugins {
        ...PluginFields
      }

      walkthroughs {
        id
        uuid
        name
        description
        steps
        meta
        createdBy {
          firstName
          lastName
          id
        }
        audioEnabled
        hasAudio
        canEdit
        canMove
        isOwner
        isInMyLibrary
        isInMyOrganizationLibrary
        canEditSharing
        canDelete
        sharedAccess
        completed
        createdAt
        canEditSharing
        viewCount
        interactionCount
        rrwebEventsUrl
        audioUrl
        liveLearning
        labs
        embedEnabled
        skillSet {
          id
          name
          uuid
          sharedAccess
        }
      }

      dataSets {
        ...DataSetFields
      }

      skillSets {
        id
        uuid
        name
      }
    }
  }

  ${USER_FIELDS}
  ${DATA_SET_FIELDS}
  ${PLUGIN_FIELDS}
`

export const SEND_AGENT_MESSAGE = gql`
  mutation sendAgentMessage(
    $agentUuid: String!
    $threadUuid: String
    $message: String!
    $chatId: String!
    $enableStreaming: Boolean!
    $fileAttachments: [String!]
    $knowledge: [KnowledgeInput!]
  ) {
    sendAgentMessage(
      agentUuid: $agentUuid
      threadUuid: $threadUuid
      message: $message
      chatId: $chatId
      enableStreaming: $enableStreaming
      fileAttachments: $fileAttachments
      knowledge: $knowledge
    ) {
      errors
      threadUuid
    }
  }
`

export const MESSAGE_FEEDBACK = gql`
  mutation messageFeedback($agentUuid: String!, $messageUuid: String!, $feedback: String!) {
    messageFeedback(agentUuid: $agentUuid, messageUuid: $messageUuid, feedback: $feedback) {
      errors
      success
    }
  }
`

export const CREATE_AGENT_MUTATION = gql`
  mutation createAgent(
    $firstName: String!
    $jobTitle: String!
    $shortDescription: String!
    $description: String!
    $primer: String!
  ) {
    createAgent(
      firstName: $firstName
      jobTitle: $jobTitle
      shortDescription: $shortDescription
      description: $description
      primer: $primer
    ) {
      success
      errors
      agent {
        firstName
        label
        jobTitle
        profileImageUrl
        shortDescription
        description
        primer
        uuid
        backend
      }
    }
  }
`

export const ADD_WALKTROUGH_TO_AGENT_MUTATION = gql`
  mutation addWalkthroughToAgent($agentUuid: String!, $walkthroughUuid: String!) {
    addWalkthroughToAgent(agentUuid: $agentUuid, walkthroughUuid: $walkthroughUuid) {
      success
      errors
    }
  }
`

export const REMOVE_WALKTROUGH_FROM_AGENT_MUTATION = gql`
  mutation removeWalkthroughFromAgent($agentUuid: String!, $walkthroughUuid: String!) {
    removeWalkthroughFromAgent(agentUuid: $agentUuid, walkthroughUuid: $walkthroughUuid) {
      success
      errors
    }
  }
`

export const ADD_SKILLSET_TO_AGENT_MUTATION = gql`
  mutation addSkillSetToAgent($agentUuid: String!, $skillSetUuid: String!) {
    addSkillSetToAgent(agentUuid: $agentUuid, skillSetUuid: $skillSetUuid) {
      success
      errors
    }
  }
`

export const REMOVE_SKILLSET_FROM_AGENT_MUTATION = gql`
  mutation removeSkillSetFromAgent($agentUuid: String!, $skillSetUuid: String!) {
    removeSkillSetFromAgent(agentUuid: $agentUuid, skillSetUuid: $skillSetUuid) {
      success
      errors
    }
  }
`

export const UPDATE_AGENT_MUTATION = gql`
  mutation updateAgent(
    $uuid: String!
    $firstName: String!
    $jobTitle: String!
    $shortDescription: String!
    $description: String!
    $primer: String!
  ) {
    updateAgent(
      uuid: $uuid
      firstName: $firstName
      jobTitle: $jobTitle
      shortDescription: $shortDescription
      description: $description
      primer: $primer
    ) {
      success
      errors
    }
  }
`

export const GET_AGENT_QUERY = gql`
  query agent($uuid: String!) {
    agent(uuid: $uuid) {
      firstName
      label
      jobTitle
      profileImageUrl
      shortDescription
      description
      description
      primer
      backend
      type
      uuid
      prompts
      id
      isPublic
      createdAt
      createdBy {
        firstName
        lastName
        id
      }
      buildStatus

      plugins {
        ...PluginFields
      }

      walkthroughs {
        id
        uuid
        name
        description
        steps
        meta
        createdBy {
          firstName
          lastName
          id
        }
        audioEnabled
        hasAudio
        canEdit
        canMove
        isOwner
        isInMyLibrary
        isInMyOrganizationLibrary
        canEditSharing
        canDelete
        sharedAccess
        completed
        createdAt
        canEditSharing
        viewCount
        interactionCount
        rrwebEventsUrl
        audioUrl
        liveLearning
        labs
        embedEnabled
        skillSet {
          id
          name
          uuid
          sharedAccess
        }
      }

      skillSets {
        id
        uuid
        name
        description
      }

      isExpert
      isCustom
      isCompany
    }
  }
  ${PLUGIN_FIELDS}
`

export const DELETE_AGENT_MUTATION = gql`
  mutation deleteAgent($uuid: String!) {
    deleteAgent(uuid: $uuid) {
      success
      errors
    }
  }
`

export const GET_PLUGIN_TEMPLATES_QUERY = gql`
  query pluginTemplates {
    pluginTemplates {
      id
      name
      type
      description
      useCases
      dataTemplate
      onePerAgent
    }
  }
`

export const ADD_PLUGIN_MUTATION = gql`
  mutation addPlugin($agentUuid: String!, $pluginTemplateId: String!, $data: JSON!) {
    addPlugin(agentUuid: $agentUuid, pluginTemplateId: $pluginTemplateId, data: $data) {
      success
      errors
    }
  }
`

export const GET_AGENT_PLUGINS_QUERY = gql`
  query plugins($agentUuid: String!) {
    plugins(agentUuid: $agentUuid) {
      ...PluginFields
    }
  }
  ${PLUGIN_FIELDS}
`

export const REMOVE_PLUGIN_MUTATION = gql`
  mutation removePlugin($id: Int!) {
    removePlugin(id: $id) {
      success
      errors
    }
  }
`

export const UPDATE_PLUGIN_MUTATION = gql`
  mutation updatePlugin($id: Int!, $config: JSON!) {
    updatePlugin(id: $id, config: $config) {
      success
      errors
    }
  }
`

export const UPDATE_AGENT_PROMPTS_MUTATION = gql`
  mutation updateAgentPrompts($uuid: String!, $prompts: [String!]!) {
    updateAgentPrompts(uuid: $uuid, prompts: $prompts) {
      success
      errors
    }
  }
`

export const MUTATION_GENERATE_PROFILE_IMAGE = gql`
  mutation generateProfileImage($uuid: String!) {
    generateProfileImage(uuid: $uuid) {
      success
      errors
    }
  }
`

export const MUTATION_REBUILD_AGENT = gql`
  mutation rebuildAgent($uuid: String!) {
    rebuildAgent(uuid: $uuid) {
      success
      errors
    }
  }
`

export const GET_AGENT_KNOWLEDGE = gql`
  query agentKnowledge($uuid: String!) {
    agentKnowledge(uuid: $uuid) {
      documentType
      uuid
      sourceType
      sourceName
      sourceUuid
    }
  }
`

export const GET_AGENT_KNOWLEDGE_ITEM = gql`
  query agentKnowledgeItem($agentUuid: String!, $itemUuid: String!) {
    agentKnowledgeItem(agentUuid: $agentUuid, itemUuid: $itemUuid) {
      documentType
      uuid
      sourceType
      sourceName
      sourceUuid
      content
    }
  }
`

export const GET_COMPANY_AGENT = gql`
  query companyAgent {
    companyAgent {
      ...CompanyAgentFields
    }
  }

  ${COMPANY_AGENT_FIELDS}
`

export const GET_EXPERT_AGENT_TEMPLATES = gql`
  query expertAgentTemplates {
    expertAgentTemplates {
      id
      uuid
      jobTitle
      shortDescription
      longDescription
      instructions
    }
  }
`

export const MUTATION_CREATE_EXPERT_AGENT = gql`
  mutation createExpertAgent($uuid: String!) {
    createExpertAgent(uuid: $uuid) {
      success
      errors
    }
  }
`
