import React, { useEffect, useState } from 'react'
import AgentSidebar from './AgentSidebar'
import ThreadList from './ThreadList'
import ChatThread from './ChatThread'
import PromptInput from '../../components/Chat/PromptInput'

const ChatSurface: React.FC = () => {
  const [selectedAgentUuid, setSelectedAgentUuid] = useState<string | null>(null)
  const [selectedThreadUuid, setSelectedThreadUuid] = useState<string | null>(null)
  const [query, setQuery] = useState<string | null>(null)

  const handleNewThreadClick = () => {
    setSelectedThreadUuid(null)
    setQuery(null)
  }

  const handleThreadClick = (threadUuid: string) => {
    setSelectedThreadUuid(threadUuid)
    setQuery(null)
  }

  const handleAgentClick = (agentUuid: string) => {
    setSelectedAgentUuid(agentUuid)
    setSelectedThreadUuid(null)
    setQuery(null)
  }

  const handlePromptSubmit = (query: string) => {
    setQuery(query)
  }

  // add the agent_uuid to the url
  useEffect(() => {
    const url = new URL(window.location.href)

    if (selectedAgentUuid) {
      // add it as a query param
      url.searchParams.set('agent_uuid', selectedAgentUuid)
      window.history.replaceState({}, '', url.toString())
    } else {
      // remove it from the url
      url.searchParams.delete('agent_uuid')
      url.searchParams.delete('thread_uuid')
      window.history.replaceState({}, '', url.toString())
    }
  }, [selectedAgentUuid])

  // add the thread_uuid to the url
  useEffect(() => {
    if (selectedThreadUuid) {
      const url = new URL(window.location.href)
      url.searchParams.set('thread_uuid', selectedThreadUuid)
      window.history.replaceState({}, '', url.toString())
    } else {
      const url = new URL(window.location.href)
      url.searchParams.delete('thread_uuid')
      window.history.replaceState({}, '', url.toString())
    }
  }, [selectedThreadUuid])

  return (
    <div className="absolute inset-0 flex flex-row h-full">
      <div className="flex flex-col h-full">
        <AgentSidebar onAgentClick={handleAgentClick} />
      </div>
      <div className="flex flex-col h-full shadow-inner">
        <ThreadList
          agentUuid={selectedAgentUuid}
          onThreadClick={handleThreadClick}
          onNewThreadClick={handleNewThreadClick}
        />
      </div>
      <div className="flex-grow flex flex-col h-full w-full bg-gray-200 shadow-inner">
        {selectedAgentUuid &&
          (selectedThreadUuid || query ? (
            <ChatThread
              agentUuid={selectedAgentUuid}
              threadUuid={selectedThreadUuid}
              query={query}
            />
          ) : (
            <div className="flex-grow flex flex-col items-center justify-center w-full">
              <div className="w-full max-w-xl">
                <PromptInput handleSubmit={handlePromptSubmit} isQuerying={false} />
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ChatSurface
