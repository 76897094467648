import React, { useEffect } from 'react'
import { hot } from 'react-hot-loader'
import * as Sentry from '@sentry/react'
import '../common/sentry'
import { ApolloProvider, useQuery } from '@apollo/client/react'
import { Route, BrowserRouter, useLocation } from 'react-router-dom'
import client from '../common/ApolloClient'
import { Provider, useDispatch } from 'react-redux'
import store, { persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import Layout from './components/Layout'
import Index from './pages/Index'
import SidebarLayout from './components/SidebarLayout'
import { GET_CURRENT_USER } from './graphql/queries/user'
import { CurrentUserQuery, CurrentUserQueryVariables } from '../graphql'
import { setOrganization, setUser } from './slices/appSlice'

import Agents from './pages/app/Agents'
import Settings from './pages/app/Settings'
import MyLibrary from './pages/app/MyLibrary'
import OrganizationLibrary from './pages/app/OrganizationLibrary'
import Profile from './pages/app/Profile'
import { QueryParamProvider } from 'use-query-params'

import WalkthroughShow from '../Walkthrough/ui/Walkthrough/Show'
import SkillSetShow from '../SkillSet/ui/SkillSet/Show'
import OneAgent from './pages/app/OneAgent'
import CreateAgent from './pages/app/CreateAgent'
import EditAgent from './pages/app/EditAgent'
import AddWalkthroughToAgent from './pages/app/AddWalkthroughToAgent'
import AddSkillSetToAgent from './pages/app/AddSkillSetToAgent'
import CreateWalkthrough from './pages/app/CreateWalkthrough'
import CreateSkillSet from './pages/app/CreateSkillSet'
import MyTeam from './pages/app/MyTeam'
import Alerts from './pages/app/Alerts'
import EditAlert from './pages/app/EditAlert'
import CreateAlert from './pages/app/CreateAlert'
import OneAlert from './pages/app/OneAlert'
import CreditUsage from './pages/app/CreditUsage'
import EditTask from './pages/app/EditTask'
import OneTask from './pages/app/OneTask'
import AgentKnowledge from './pages/app/AgentKnowledge'
import Analyses from './pages/app/Analyses'
import OneAnalysis from './pages/app/OneAnalysis'
import CreateAnalysis from './pages/app/CreateAnalysis'
import EditAnalysis from './pages/app/EditAnalysis'
import DataSets from './pages/app/DataSets'
import EditDataSet from './pages/app/EditDataSet'
import OneDataSet from './pages/app/OneDataSet'
import CreateDataSet from './pages/app/CreateDataSet'
import CompanyAgent from './pages/app/Home'
import OneAgentChat from './pages/app/OneAgentChat'
import AnalysisRun from './pages/app/OneAnalysis/AnalysisRun'
import ChatSurface from './pages/ChatSurface'

export const RefetchUserContext = React.createContext(null)

const routeTitleMapping: Record<string, string> = {
  '/home': 'Work Intelligence Platform',
  '/settings': 'Settings',
  '/my-team': 'My Team',
  '/data_sets': 'Data Sets',
  '/my_library': 'My Library',
  '/organization_library': 'Organization Library',
  '/profile': 'Profile',
  '/agents': 'Helpers',
  '/walkthrough/create': 'Create Walkthrough',
  '/skill_set/create': 'Create Skill Set',
  '/alerts': 'Alerts',
  '/alert/create': 'Create Alert',
  '/credits': 'Credit Usage',
}

type AppProps = {
  children: React.ReactNode
}

const App = ({ children }: AppProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { loading, data, refetch } = useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    GET_CURRENT_USER
  )

  const refetchUserData = () => {
    refetch()
  }

  useEffect(() => {
    const currentPath = location.pathname
    if (!currentPath || !routeTitleMapping[currentPath]) {
      return
    }
    const title = routeTitleMapping[currentPath] + ' - FlowMo'
    document.title = title
  }, [location])

  useEffect(() => {
    if (loading || !data || !data.currentUser) {
      return
    }
    const currentUser = data.currentUser
    const organization = data.currentUser.organization

    Sentry.setUser({
      id: currentUser.id,
      email: currentUser.email,
    })

    dispatch(
      setUser({
        firstName: currentUser.firstName || '',
        lastName: currentUser.lastName || '',
        email: currentUser.email || '',
        id: parseInt(currentUser.id || '0'),
        roles: (currentUser.roles || []).map((role) => {
          return role.name
        }),
        isAdmin: false,
        isOrganizationAdmin: false,
      })
    )

    dispatch(
      setOrganization({
        name: organization.name || '',
        id: parseInt(organization.id || '0'),
        emailNamespace: organization.emailNamespace || '',
      })
    )
  }, [loading, data])

  return (
    <React.Fragment>
      <RefetchUserContext.Provider value={refetchUserData}>{children}</RefetchUserContext.Provider>
    </React.Fragment>
  )
}

type AppTypeProps = {
  logoutPath: string
  csrfToken: string
  isImpersonating: boolean
}

const InternalApp = ({ logoutPath, csrfToken, isImpersonating }: AppTypeProps) => (
  <App>
    <React.Fragment>
      <SidebarLayout
        logoutPath={logoutPath}
        csrfToken={csrfToken}
        isImpersonating={isImpersonating}
      >
        <Route path="/home" component={CompanyAgent}></Route>
        <Route path="/agents" component={Agents}></Route>
        <Route path="/settings" component={Settings}></Route>
        <Route path="/my-team" component={MyTeam}></Route>
        <Route path="/my_library" component={MyLibrary}></Route>
        <Route path="/alerts" component={Alerts} exact></Route>
        <Route path="/analyses" component={Analyses} exact></Route>

        <Route
          exact
          path="/analysis/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <OneAnalysis uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/analysis/run/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <AnalysisRun uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/analysis/edit/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <EditAnalysis uuid={props.match.params.uuid} />}
        />

        <Route path="/analysis/create" component={CreateAnalysis} exact></Route>

        <Route path="/alert/create" component={CreateAlert} exact></Route>
        <Route
          path="/alert/edit/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          exact
          render={(props) => <EditAlert uuid={props.match.params.uuid} />}
        ></Route>
        <Route
          path="/alert/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          exact
          render={(props) => <OneAlert uuid={props.match.params.uuid} />}
        ></Route>
        <Route
          path="/task/edit/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          exact
          render={(props) => <EditTask uuid={props.match.params.uuid} />}
        ></Route>
        <Route
          path="/task/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          exact
          render={(props) => <OneTask uuid={props.match.params.uuid} />}
        ></Route>
        <Route path="/organization_library" component={OrganizationLibrary} exact></Route>
        <Route path="/profile" component={Profile} exact></Route>
        <Route path="/walkthrough/create" component={CreateWalkthrough} exact></Route>
        <Route path="/skill_set/create" component={CreateSkillSet} exact></Route>
        <Route
          exact
          path="/walkthrough/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <WalkthroughShow uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/skill_set/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <SkillSetShow uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/walkthrough/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/add_to_agent"
          render={(props) => <AddWalkthroughToAgent uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/skill_set/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/add_to_agent"
          render={(props) => <AddSkillSetToAgent uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/agent/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <OneAgent uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/agent/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/chat"
          render={(props) => <OneAgentChat uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/agent/knowledge/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <AgentKnowledge uuid={props.match.params.uuid} />}
        />

        <Route path="/chat" component={ChatSurface} exact></Route>

        <Route
          exact
          path="/agent/edit/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <EditAgent uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/data_set/edit/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <EditDataSet uuid={props.match.params.uuid} />}
        />

        <Route
          exact
          path="/data_set/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})"
          render={(props) => <OneDataSet uuid={props.match.params.uuid} />}
        />

        <Route exact path="/data_sets" component={DataSets} />
        <Route exact path="/data_set/create" component={CreateDataSet} />

        <Route exact path="/agent/create" component={CreateAgent} />
        <Route exact path="/credits" component={CreditUsage} />
      </SidebarLayout>
    </React.Fragment>
  </App>
)

const ExternalApp = ({ logoutPath, csrfToken, isImpersonating }: AppTypeProps) => (
  <App>
    <React.Fragment>
      <Layout logoutPath={logoutPath} csrfToken={csrfToken} isImpersonating={isImpersonating}>
        <Route path="/" component={Index}></Route>
      </Layout>
    </React.Fragment>
  </App>
)

type AppProviderProps = {
  logoutPath: string
  csrfToken: string
  isImpersonating: boolean
  mountPoint: string
  isInternal: boolean
}

const AppProvider = ({
  logoutPath,
  csrfToken,
  isImpersonating,
  mountPoint,
  isInternal,
}: AppProviderProps) => (
  <Sentry.ErrorBoundary>
    <ApolloProvider client={client}>
      <BrowserRouter basename={mountPoint}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryParamProvider ReactRouterRoute={Route}>
              {isInternal ? (
                <InternalApp
                  logoutPath={logoutPath}
                  csrfToken={csrfToken}
                  isImpersonating={isImpersonating}
                />
              ) : (
                <ExternalApp
                  logoutPath={logoutPath}
                  csrfToken={csrfToken}
                  isImpersonating={isImpersonating}
                />
              )}
            </QueryParamProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ApolloProvider>
  </Sentry.ErrorBoundary>
)

export default hot(module)(AppProvider)
