import React from 'react'
import PageHeader from '../../components/PageHeader'
import DynamicForm from '../../components/DynamicForm'
import Spin from '../../../common/ui/Spin'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { CREATE_WORKFLOW_MUTATION } from '../../graphql/queries/workflow'
import {
  CreateWorkflowMutation,
  CreateWorkflowMutationVariables,
  OrganizationAgentsQuery,
  OrganizationAgentsQueryVariables,
} from 'app/javascript/components/graphql'
import { GET_ORGANIZATION_AGENTS_QUERY } from '../../graphql/queries/agent'

export const workflowFormData = (agents: OrganizationAgentsQuery['organizationAgents']) => [
  {
    name: 'name',
    label: 'Name',
    type: 'string',
    required: true,
    defaultValue: '',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    required: true,
    defaultValue: '',
  },
  {
    name: 'agentUuid',
    label: 'Agent',
    type: 'select',
    required: true,
    defaultValue: '',
    options: agents?.map((agent) => ({ label: agent.label, value: agent.uuid })),
  },
  {
    name: 'cronSchedule',
    label: 'Schedule',
    type: 'timeFrameSelector',
    required: false,
    defaultValue: '',
  },
  {
    name: 'webhookUrl',
    label: 'Webhook URL',
    type: 'url',
    required: false,
    defaultValue: '',
  },
]

const CreateAlert = () => {
  const formRef = React.useRef<{ submitForm: () => Promise<void> }>(null)
  const history = useHistory()
  const [isSaving, setIsSaving] = React.useState(false)
  const [createWorkflow] = useMutation<CreateWorkflowMutation, CreateWorkflowMutationVariables>(
    CREATE_WORKFLOW_MUTATION
  )
  const { data, loading } = useQuery<OrganizationAgentsQuery, OrganizationAgentsQueryVariables>(
    GET_ORGANIZATION_AGENTS_QUERY,
    { fetchPolicy: 'network-only' }
  )

  if (loading) {
    return <Spin />
  }

  const agents = data?.organizationAgents

  const pages = [{ title: 'Alerts', to: '/alerts' }, { title: 'Create' }]

  const handleSave = async ({
    name,
    description,
    agentUuid,
    cronSchedule,
    webhookUrl,
  }: {
    name: string
    description: string
    task: string
    agentUuid: string
    cronSchedule: string
    webhookUrl: string
  }) => {
    setIsSaving(true)

    const response = await createWorkflow({
      variables: { name, description, agentUuid, cronSchedule, webhookUrl },
    })

    if (response.data.createWorkflow.errors.length > 0) {
      window.toastr.error('Error adding alert')
    } else {
      window.toastr.success('Alert created successfully')
      history.push('/alert/' + response.data.createWorkflow.workflow.uuid)
    }
    setIsSaving(false)
  }

  const workflowDetailsFormData = workflowFormData(agents)
  return (
    <React.Fragment>
      <PageHeader title="Create Alert" breadCrumbs={pages} />
      <div className="max-w-2xl">
        <DynamicForm ref={formRef} formData={workflowDetailsFormData} handleSubmit={handleSave} />
        <button
          type="submit"
          onClick={() => formRef.current?.submitForm()}
          className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-flowmo-blue-700 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-flowmo-blue-800"
          disabled={isSaving}
        >
          {isSaving ? <Spin /> : 'Create'}
        </button>
      </div>
    </React.Fragment>
  )
}
export default CreateAlert
